<template>
  <div class="card">
    <DataTable
        responsiveLayout="stack"
        :value="data"
        v-model:selection="selecionado"
        selectionMode="single"
        showGridlines
        @sort="sort($event)">

      <template #empty>
        Nenhum registro encontrado.
      </template>

      <template #loading>
        Carregando... Por favor, aguarde.
      </template>

      <Column field="contratoInternoId" header="CI" style="width:4rem;"/>

      <Column field="statusEmbarque" header="Status" style="width:6rem;">
        <template #body="{ data, field }">
          {{ getDescricaoStatus(data[field]) }}
        </template>
      </Column>

      <Column field="cnpj" header="CNPJ" />

      <Column field="destino" header="Destino">
        <template #body="{ data, field }">
          {{ data[field].nome }}/{{ data[field].uf }}
        </template>
      </Column>

      <Column field="terminal" header="Terminal">
        <template #body="{ data }">
          {{ data.terminal == null ? "-" : data.terminal.nomeFantasia  }}
        </template>
      </Column>

      <Column field="nomeCliente" header="Cliente" />

      <Column field="inicioEmbarque" header="Inicio" style="width:6rem;">
        <template #body="{ data, field }">
          {{ formatDateCustom(data[field], "DD/MM/YY") }}
        </template>
      </Column>

      <Column field="fimEmbarque" header="Fim" style="width:6rem;">
        <template #body="{ data, field }">
          {{ formatDateCustom(data[field], "DD/MM/YY") }}
        </template>
      </Column>

      <Column field="descProduto" header="Produto"/>

      <Column field="saldoContrato" header="Saldo Contrato" style="text-align: right;">
        <template #body="{ data, field }">
          {{ formatDecimal(data[field], 2, 2) }} {{ getSiglaUnidadeMedida(data.unidade) }}
        </template>
      </Column>

      <Column field="saldoEmbarque" header="Saldo Embarque" style="text-align: right;">
        <template #body="{ data, field }">
          {{ formatDecimal(data[field], 2, 2) }} {{ getSiglaUnidadeMedida(data.unidade) }}
        </template>
      </Column>

      <Column field="saldo" header="Saldo" style="text-align: right;">
        <template #body="{ data, field }">
          {{ formatDecimal(data[field], 2, 2) }} {{ getSiglaUnidadeMedida(data.unidade) }}
        </template>
      </Column>

      <Column header="Ações" style="width:12rem;" class="centralizar-titulo-tabela">
        <template #body="{ data }">
          <div class="flex-none">

            <Button
                title="Visualizar histórico"
                icon="pi pi-list"
                class="p-button-rounded p-button-warning flex-none mr-2"
                @click="visualizarHistorico(data)"
            ></Button>

            <Button
                v-if="data.ativo"
                title="Editar"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-info flex-none mr-2"
                @click="editar(data.id)"
            ></Button>

            <Button
                v-if="data.ativo"
                title="Inativar"
                class="p-button-rounded flex-none p-button-danger"
                icon="pi pi-ban"
                @click="confirmarAtivarOuInativar(data)"
            ></Button>

            <Button
                v-if="!data.ativo"
                title="Ativar"
                class="p-button-rounded flex-none p-button-info"
                icon="pi pi-check-circle"
                @click="confirmarAtivarOuInativar(data)"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>

    <Paginator
        v-model:first="primeiraLinha"
        :rows="qtdRegistroPorPagina"
        :total-records="totalRegistro"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        @page="onPage" />

  </div>

  <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
      <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o embarque do CI: <strong>{{ selecionado.contratoInternoId }}</strong>?</span>
    </div>
    <template #footer>
      <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
      <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
    </template>
  </Dialog>

  <ModalHistoricoGenerico
      codigoPagina="EMBARQUE"
      :id="selecionado.id"
      :descricao="descricaoHistoricoDialog"
      :visivel="historicoDialog"
      @fechar="ocultarHistorico()"
  />

</template>

<script>

import Formatacao from '../../../utilities/Formatacao';
import StorageService from '../../../service/StorageService';
import EmbarqueService from '../../../service/EmbarqueService';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import UnidadeMedidaService from '../../cadastro/contrato/service/UnidadeMedidaService.js';

export default {
  name: 'EmbarqueForm',
  components: { ModalHistoricoGenerico },
  props: {
    filtro: { type: Object, required: true}
  },
  data() {
    return {
      pagina: 0,
      primeiraLinha: 0,
      totalRegistro: 0,
      qtdRegistroPorPagina: 10,

      data: [],

      filter: {},
      ordenar: 'id,desc',
      periodoInicio: null,
      periodoFim: null,

      selecionado: {},
      historicoDialog: false,
      ativarOuInativarDialog: false,
      descricaoHistoricoDialog: null,

      storange: StorageService.getControlePagina("GESTAO-EMBARQUE"),
      storangeMessage: StorageService.getMensagemToast("GESTAO-EMBARQUE")
    }
  },

  updated() {
    this.carregarDados();
  },

  created() {

    this.filter = this.filtro;

    this.pagina = this.storange.numero;
    this.primeiraLinha = this.storange.firstRow;
    this.qtdRegistroPorPagina = this.storange.qtdRegistro;
    this.carregarDados();
    this.exibirMensagemToast();
  },

  methods: {

    exibirMensagemToast() {
      if (this.storangeMessage.active){
        this.$toast.add({
          severity: this.storangeMessage.severity,
          summary: this.storangeMessage.summary,
          detail: this.storangeMessage.detail,
          life: 7500
        });
        StorageService.setMensagemToast("GESTAO-EMBARQUE", 'info', '', '', false);
      }
    },

    cadastrar() {
      this.$router.push(`/embarque/cadastro`);
    },

    editar(id) {
      this.$router.push(`/embarque/editar/${ id }`);
    },

    carregarDados() {

      EmbarqueService.buscarComPaginacao(this.filter)
          .then(({ data }) => {
            if(data) {
              this.data = data.content;
              this.qtdRegistroPorPagina = data.pageable.pageSize;
              this.totalRegistro = data.totalElements;
            }
          })
          .catch(error => {
            const toast = {
              severity:'error',
              summary: 'Falha ao buscar os embarques',
              detail:`Falha não mapeada.`,
              life: 7500
            };

            if(!!error && !!error.response && !!error.response.data) {
              const { message } = error.response.data;
              toast["detail"] = message;
            }

            this.$toast.add(toast);
          });

      StorageService.setControlePaginaFilter("EMBARQUE", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
    },

    confirmarAtivarOuInativar(embarque) {
      this.selecionado = embarque;
      this.ativarOuInativarDialog = true;
    },

    visualizarHistorico(embarque) {
      this.descricaoHistoricoDialog = "Contrato Interno: "+ embarque.contratoInternoId;
      this.historicoDialog = true;
      this.selecionado = embarque;
    },

    ocultarHistorico() {
      this.historicoDialog = false;
      this.selecionado = {};
    },

    ativarOuInativar() {
      if(this.selecionado.ativo) {
        EmbarqueService.inativar(this.selecionado.id)
            .then(response => {
              this.$toast.add({
                severity:'success',
                summary: 'Inativado',
                detail:`O embarque foi inativado com sucesso.`,
                life: 7500
              });

              this.carregarDados();
              this.ativarOuInativarDialog = false;
              this.selecionado = {};
            })
            .catch(error => {
              const toast = {
                severity:'error',
                summary: 'Falha ao inativar',
                detail:`Falha não mapeada.`,
                life: 7500
              };

              if(!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
            });
      }
      else {
        EmbarqueService.ativar(this.selecionado.id)
            .then(response => {
              this.$toast.add({
                severity:'success',
                summary: 'Ativada',
                detail:`O embarque foi ativado com sucesso.`,
                life: 7500
              });

              this.carregarDados();
              this.ativarOuInativarDialog = false;
              this.selecionado = {};
            })
            .catch(error => {
              const toast = {
                severity:'error',
                summary: 'Falha ao ativar',
                detail:`Falha não mapeada.`,
                life: 7500
              };

              if(!!error && !!error.response && !!error.response.data) {
                const { message } = error.response.data;
                toast["detail"] = message;
              }

              this.$toast.add(toast);
            });
      }

      this.ativarOuInativarDialog = false;
    },

    formatDateCustom(date, format) {
      return Formatacao.formatDateCustom(date, format);
    },

    formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
    },

    onPage(event) {
      this.pagina = event.page + 1;
      this.primeiraLinha = event.rows * event.page;
      this.qtdRegistroPorPagina = event.rows;
      this.carregarDados();
    },

    sort(event) {
      const sortField = event.sortField;
      const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
      this.ordenar = `${ sortField },${sortOrder}`;
      this.carregarDados();
    },

    getSiglaUnidadeMedida(codigo){
      if(codigo){
        const unidadeMedida = UnidadeMedidaService.buscarPorCodigo(codigo);

        if(unidadeMedida){
          return unidadeMedida.sigla;
        }else{
          return "";
        }
      }
    },

    getDescricaoStatus(status){

      if(status){
        switch(status){
          case 'A_INICIAR':
            return "À Iniciar";
          case 'INICIADO':
            return "Iniciado"
          case 'CONCLUÍDO':
            return "Concluído"
          default:
            return null;
        };
      }
    }
  }
}
</script>

<style>
.centralizar-titulo-tabela {
  text-align: center !important;
}

.centralizar-titulo-tabela .p-column-header-content {
  display: inline;
}
</style>