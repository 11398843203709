<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="layout/images/truck.png" alt="" style="font-size: 1.4rem">
           <h3 style="margin:0px 5px;">Gestão de Embarque</h3>
        </div>

        <hr />

                <div class="flex align-items-center">
                    <div class="field mr-2">
                        <label>
                            <strong>Cliente</strong>
                        </label>

                        <InputText class="w-full" v-model="nomeCliente" />
                    </div>

                    <div class="field mr-2">
                        <label>
                            <strong>Período</strong>
                        </label>

                        <div class="flex align-items-center">
                            <div class="overflow-hidden" style="width:6rem;">
                                <Calendar
                                    class="w-full"
                                    v-model="periodoInicio"
                                    autocomplete="off"
                                    dateFormat="dd/mm/y"
                                />
                            </div>

                            <strong class="ml-2 mr-2">à</strong>

                            <div class="overflow-hidden" style="width:6rem;">
                                <div class="p-inputgroup">
                                    <Calendar
                                        class="w-full"
                                        v-model="periodoFim"
                                        autocomplete="off"
                                        dateFormat="dd/mm/y"
                                        :minDate="periodoInicio"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarDados()"
                            ></Button>

                            <Button
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar ativos"
                        class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        label="Cadastrar"
                        icon="pi pi-plus"
                        class="p-button-info mt-2 mr-2"
                        @click="cadastrar()"
                    ></Button>
                </div>

        <EmbarqueForm :filtro=this.filter />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o embarque do CI: <strong>{{ selecionado.contratoInternoId }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

</template>

<script>

import Formatacao from '../../../utilities/Formatacao';
import StorageService from '../../../service/StorageService';
import EmbarqueService from '../../../service/EmbarqueService';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import UnidadeMedidaService from '../../cadastro/contrato/service/UnidadeMedidaService.js';
import EmbarqueForm from "@/components/cadastro/embarque/EmbarqueForm.vue";

export default {
    name: 'EmbarqueList',
    emits: ["carregarDados"],
    components: {EmbarqueForm, ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            nomeCliente: null,
            ordenar: 'id,desc',
            periodoInicio: null,
            periodoFim: null,
            
            selecionado: {},

            storange: StorageService.getControlePagina("GESTAO-EMBARQUE"),
            storangeMessage: StorageService.getMensagemToast("GESTAO-EMBARQUE")
        }
    },

    created() {
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        //this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {

        carregarDados(){
          this.filter["page"] = this.pagina;
          this.filter["size"] = this.qtdRegistroPorPagina;
          this.filter["sort"] = this.ordenar;
          this.filter["periodoInicio"] = undefined;
          this.filter["periodoFim"] = undefined;

          if(!!this.periodoInicio) {
            this.filter["periodoInicio"] = this.formatDateCustom(this.periodoInicio, "YYYY-MM-DD");
          }

          if(!!this.periodoFim) {
            this.filter["periodoFim"] = this.formatDateCustom(this.periodoFim, "YYYY-MM-DD");
          }

          this.filter.nomeCliente = this.nomeCliente;
        },

        exibirMensagemToast() {
          if (this.storangeMessage.active){
            this.$toast.add({
              severity: this.storangeMessage.severity,
              summary: this.storangeMessage.summary,
              detail: this.storangeMessage.detail,
              life: 7500
            });
            StorageService.setMensagemToast("GESTAO-EMBARQUE", 'info', '', '', false);
          }
        },

        limparFiltro() {
            this.nomeCliente = undefined;
            this.filter["nomeCliente"] = undefined;
            this.filter["periodoInicio"] = undefined;
            this.filter["periodoFim"] = undefined;

            this.periodoInicio = null;
            this.periodoFim = null;

        },

        cadastrar() {
          this.$router.push(`/embarque/cadastro`);
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        getSiglaUnidadeMedida(codigo){
            if(codigo){
                const unidadeMedida = UnidadeMedidaService.buscarPorCodigo(codigo);

                if(unidadeMedida){
                    return unidadeMedida.sigla;
                }else{
                    return "";
                }
            }
        },

        getDescricaoStatus(status){
            
            if(status){
                switch(status){
                    case 'A_INICIAR':
                        return "À Iniciar";
                    case 'INICIADO':
                        return "Iniciado"
                    case 'CONCLUÍDO':
                        return "Concluído"
                    default:
                        return null;
                };
            }
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
